import Alpine from 'alpinejs';
import focus from '@alpinejs/focus'

// Testing Shoelace for Select Dropdown for now, not sure if final
import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/themes/dark.css';
import '@shoelace-style/shoelace/dist/components/select/select.js';
import '@shoelace-style/shoelace/dist/components/option/option.js';

import './site/auth';
import './site/theme-switcher';
import './site/awards-search';
import './site/video-library';

import.meta.glob([
    '../img/**',
]);

Alpine.plugin(focus);
window.Alpine = Alpine;
Alpine.start();
