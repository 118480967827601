import Alpine from 'alpinejs';

Alpine.data('logoutForm', () => ({
    error: '',
    submitting: false,

    async submitLogout() {
        this.error = '';
        this.submitting = true;

        try {
            await this.$store.auth.logout();
        } catch (error) {
            this.error = error.message;
        } finally {
            this.submitting = false;
        }
    },

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}));
