import Alpine from 'alpinejs';

Alpine.data('videoLibraryGrid', () => ({
    playingVideoId: null,
    currentPlayerInstance: null,

    init() {
        this.playFirstVideoIfComingFromAutoPlay();
    },

    playVideo(videoId) {
        this.playingVideoId = videoId;

        this.$nextTick(() => {
            const gridItemElement = document.getElementById(`video-gallery-item-${videoId}`);
            const playerElement = gridItemElement.querySelector('.video-js');

            const playerId = playerElement.getAttribute('data-player');
            const embedId = playerElement.getAttribute('data-embed');

            const brightcove = window.bc[`${playerId}_${embedId}`] || window.bc;

            if (this.currentPlayerInstance) {
                this.currentPlayerInstance.dispose();
            }

            this.currentPlayerInstance = brightcove(playerElement);

            this.currentPlayerInstance.play();

            this.currentPlayerInstance.on('ended', () => {
                this.playNextVideo(gridItemElement);
            });
        });
    },

    playNextVideo(currentGridItemElement) {
        const nextGridItemElement = currentGridItemElement.nextElementSibling;

        if (!nextGridItemElement) {
            const paginationContainer = document.querySelector('#video-library-pagination');

            if (!paginationContainer) {
                return;
            }

            const nextPageUrl = paginationContainer.getAttribute('data-next-page-url');

            if (!nextPageUrl) {
                return;
            }

            window.location = nextPageUrl + '#auto-play-' + window.scrollY;

            return;
        }

        const nextVideoId = nextGridItemElement.id.replace('video-gallery-item-', '');

        this.playVideo(nextVideoId);
    },

    playFirstVideoIfComingFromAutoPlay() {
        if (window.location.hash.startsWith('#auto-play')) {
            const firstVideoElement = document.querySelector('.video-library__grid-item');

            if (firstVideoElement) {
                const firstVideoId = firstVideoElement.id.replace('video-gallery-item-', '');

                this.playVideo(firstVideoId);
            }

            const scrollPosition = parseFloat(window.location.hash.replace('#auto-play-', ''));
            if (!isNaN(scrollPosition)) {
                window.scrollTo(0, scrollPosition);
            }

            history.replaceState(null, null, ' ');
        }
    }
}));
