import Alpine from 'alpinejs';

Alpine.data('loginForm', () => ({
    username: '',
    password: '',
    error: '',
    submitting: false,

    async submitLogin() {
        this.error = '';
        this.submitting = true;


        try {
            await this.$store.auth.login(this.username, this.password);

            this.username = '';
        } catch (error) {
            this.error = error.message;
        } finally {
            this.submitting = false;
            this.password = '';
        }
    },
}));
