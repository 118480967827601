import Alpine from 'alpinejs';

Alpine.store('themeSwitcher', {
    theme: 'light',

    init() {
        this.loadCurrentThemeSetting();
        this.applyTheme();
    },

    toggleTheme() {
        this.theme = this.theme === 'light' ? 'dark' : 'light';

        localStorage.setItem('theme', this.theme);

        this.applyTheme();
    },

    loadCurrentThemeSetting() {
        const localStorageTheme = localStorage.getItem('theme');

        if (localStorageTheme) {
            this.theme = localStorageTheme;
            return;
        }

        const systemSettingDark = window.matchMedia("(prefers-color-scheme: dark)");

        this.theme = systemSettingDark.matches ? 'dark' : 'light';
    },

    applyTheme() {
        const htmlElement = document.querySelector("html");
        htmlElement.setAttribute("data-theme", this.theme);
        if (this.theme === 'dark') {
            htmlElement.classList.add('sl-theme-dark');
        } else {
            htmlElement.classList.remove('sl-theme-dark');
        }
    },

    get buttonText() {
        return this.theme === 'light'
            ? 'Change to dark theme'
            : 'Change to light theme';
    }
});
